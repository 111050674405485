<div class="container-fluid page-footer">
  <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4">
    <div class="col-md-4 d-flex align-items-center">
      <span>©2022 by David Sousa</span>
    </div>

    <ul class="nav col-md-4 justify-content-end list-unstyled d-flex">
      <li class="ms-3"><a href="https://kumasousa.itch.io" target="_blank"><i class="fa-brands fa-itch-io"></i></a></li>
      <li class="ms-3"><a href="https://twitter.com/KumaSousa" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
      <li class="ms-3"><a href="https://www.linkedin.com/in/david-sousa-43622611a/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
    </ul>
  </footer>
</div>
