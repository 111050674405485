import { Language } from "./language.model";
import { Project } from "./project.model";
import { Tool } from "./tool.model";
import { Technology } from "./technology.model";

export class ProjectsService {

  private projects: Project[] = [];
  private languages: Language[] = [];
  private tools: Language[] = [];
  private technologies: Language[] = [];
  private databases: Language[] = [];

  constructor()
  {
    this.projects.push
    (
      //----
      {
        name: "Space Shooter",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/SpaceShooter%2FSpaceShooter.png?alt=media&token=0fcf0197-3562-438a-9fc4-7340ac6b9f49",
        order: 3,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/SpaceShooter%2FSpaceShooter.png?alt=media&token=0fcf0197-3562-438a-9fc4-7340ac6b9f49",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/SpaceShooter%2FSpaceShooterVideo.mp4?alt=media&token=55e5fbfd-22cb-4807-98ae-1f07a04016e8",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/SpaceShooter%2FSpaceShooterPreview1.png?alt=media&token=f41f8ddd-d173-496b-9c62-e0b7403fc758",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/SpaceShooter%2FSpaceShooterPreview2.png?alt=media&token=6e039ca8-3ac5-4b48-99e5-1ffcadd207dc",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/SpaceShooter%2FSpaceShooterPreview3.png?alt=media&token=5020cade-6340-4a27-ae63-4d1498f6e49a",
          ],
        description: "You have to recover the ship from android enemies! In this game the enemies have a field of vision and will try to look for and attack the player.",
        finishDate: new Date(1646167655602),
        engine: "Unreal",
        downloadUrl: "https://drive.google.com/file/d/1L1AD9l6gXsnS06DGy3mAWU92G9Wir64j/view?usp=sharing"
      },
      //----
      {
        name: "Toon Tanks",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ToonTanks%2FToonTanks.png?alt=media&token=e7bf2310-18ae-4a0b-a967-9bafe5688efc",
        order: 5,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ToonTanks%2FToonTanks.png?alt=media&token=e7bf2310-18ae-4a0b-a967-9bafe5688efc",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ToonTanks%2FToonTanksVideo.mp4?alt=media&token=c57264d6-dc05-4e62-9e56-36caa9d5f71a",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ToonTanks%2FToonTanksPreview1.png?alt=media&token=106e44a9-877f-430b-afa9-e9a2c3138615",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ToonTanks%2FToonTanksPreview2.png?alt=media&token=03572bbd-f327-4557-b57c-b4f5c8bb5d35",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ToonTanks%2FToonTanksPreview3.png?alt=media&token=90b9b4c0-5ab0-4ca0-89b2-c00305ac59c7",
          ],
        description: "A game that requires dexterity to handle a tank in order to hit enemy turrets without being attacked back. ",
        finishDate: new Date(1645527968000),
        engine: "Unreal",
        downloadUrl: "https://drive.google.com/file/d/1PtT4Vy60usHoiPZ22OIpsLNQmF9E721i/view?usp=sharing"
      },
      //----
      {
        name: "Realm Rush",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/RealmRush%2FRealmRush.png?alt=media&token=48c09d76-70db-46a7-9019-d20206969494",
        order: 20,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/RealmRush%2FRealmRush.png?alt=media&token=48c09d76-70db-46a7-9019-d20206969494",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/RealmRush%2FRealmRushVIdeo.mp4?alt=media&token=b34caabe-51ac-4d2b-bc8c-08a2ab6fa392",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/RealmRush%2FRealmRushPreview1.png?alt=media&token=64c889db-b356-4b2e-ba4b-e7c55dca90f4",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/RealmRush%2FRealmRushPreview2.png?alt=media&token=cbad15be-a893-4602-a85a-555a642095c9",
          ],
        description: "Tower Defense game, which the player must defend the castle from the invaders! The enemy will fin the sortest path, so the player must enlarge the path by blocking the path with the turrets.",
        finishDate: new Date(1643673600000),
        engine: "Unity",
        downloadUrl: ""
      },
      //----
      {
        name: "Parasite Night",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ParasiteNight%2FParasiteNight.png?alt=media&token=8641195c-df99-4bb4-84d4-f45314b40078",
        order: 10,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ParasiteNight%2FParasiteNight.png?alt=media&token=8641195c-df99-4bb4-84d4-f45314b40078",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ParasiteNight%2FParasiteNightVideo.mp4?alt=media&token=9666322e-3e44-410e-8986-c08c037a5539",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ParasiteNight%2FParasiteNightPreview1.png?alt=media&token=3ee0cbd5-fb93-45f8-aaaa-d4e6048a81fc",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ParasiteNight%2FParasiteNightPreview2.png?alt=media&token=2b3f0bf1-bef1-4d7c-a146-c4c666f75013",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ParasiteNight%2FParasiteNightPreview3.png?alt=media&token=66315667-2ea3-457c-b50f-09e86e003d1b",
          ],
        description: "Adventure game in which the player has to find out why the city is being invaded by nocturnal parasites, he tries to explore the top of the mountain that has a castle and discovers strange information.",
        finishDate: new Date(1638230400000),
        engine: "Unity",
        downloadUrl: ""
      },
      //----
      {
        name: "Cyber Cyborg",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/CyberCyborg%2FCyberCyborg.png?alt=media&token=9b79471d-aa75-4ed9-9a70-1be00da83735",
        order: 30,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/CyberCyborg%2FCyberCyborg.png?alt=media&token=9b79471d-aa75-4ed9-9a70-1be00da83735",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/CyberCyborg%2FCyberCyborgVideo.mp4?alt=media&token=56de6907-0089-45f3-841a-f9a88429cfdb",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/CyberCyborg%2FCyberCyborgPreview1.png?alt=media&token=98a1a152-2a97-4d01-a678-5337379e58f1",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/CyberCyborg%2FCyberCyborgPreview2.png?alt=media&token=9fbaf7fd-afe9-43c9-bbe5-51262bb684fb",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/CyberCyborg%2FCyberCyborgPreview3.png?alt=media&token=dc35fde4-19ce-474b-a0fa-f61847f3aeaf",
          ],
        description: "Infinite runner game, in which the objective is to survive as long as possible, avoiding being captured. The player can pick up power-ups to help him survive longer.",
        finishDate: new Date(1633737600000),
        engine: "Unity",
        downloadUrl: "https://barbella.itch.io/cybercyborg"
      },
      //----
      {
        name: "Snake Learn",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/SnakeLearn%2FSnakeLearn.png?alt=media&token=11d9794f-fddb-4809-b7d0-502ef5e70de4",
        order: 40,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/SnakeLearn%2FSnakeLearn.png?alt=media&token=11d9794f-fddb-4809-b7d0-502ef5e70de4",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/SnakeLearn%2FSnakeLearnVideo.mp4?alt=media&token=795717bb-078a-4638-8557-d857e2e1a01f",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/SnakeLearn%2FSnakeLearnPreview1.png?alt=media&token=79d03ee8-6f54-437d-9852-f25d27f5f6e2",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/SnakeLearn%2FSnakeLearnPreview2.png?alt=media&token=e44cfcb3-a8a8-4b32-9eb8-57f89a990c7d",
          ],
        description: "Children's educational game combining the snake game with quizzes. The objective is to grow by picking up the fruits that match the correct answer to the quiz.",
        finishDate: new Date(1582416000000),
        engine: "Godot",
        downloadUrl: "https://snakegame-ef7fc.firebaseapp.com/"
      },
      //----
      {
        name: "Zombie Runner",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ZombieRunner%2FZombieRunner.png?alt=media&token=29409630-ccc4-4135-aa4d-f1d35137af94",
        order: 50,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ZombieRunner%2FZombieRunner.png?alt=media&token=29409630-ccc4-4135-aa4d-f1d35137af94",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ZombieRunner%2FZombieRunnerVideo.mp4?alt=media&token=35427456-4173-4b87-acc7-0a288c10495c",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ZombieRunner%2FZombieRunnerPreview1.png?alt=media&token=3f745b85-7dff-4847-8312-a5206d7403a7",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ZombieRunner%2FZombieRunnerPreview2.png?alt=media&token=dfd70bb8-8dab-471b-a85c-b263b07e51a2",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ZombieRunner%2FZombieRunnerPreview3.png?alt=media&token=b93c50d6-bf8f-41ab-8854-4c9c42665e29",
          ],
        description: "Zombie survival game prototype. You try to escape the forest while zombies are chasing you! Look for ammo to defend yourself and try to find a way out.",
        finishDate: new Date(1624492800000),
        engine: "Unity",
        downloadUrl: ""
      },
      //----
      {
        name: "Ping Pong",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/PingPong%2FPingPong.png?alt=media&token=8e389b46-9b09-4f93-8bd0-3c5eada2f0e2",
        order: 60,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/PingPong%2FPingPong.png?alt=media&token=8e389b46-9b09-4f93-8bd0-3c5eada2f0e2",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/PingPong%2FPingPongVideo.mp4?alt=media&token=d1057885-d767-4865-a7d7-d8e70042851c",
          ],
        description: "Classic pong game with a retro style.",
        finishDate: new Date(1546819200000),
        engine: "Unity",
        downloadUrl: ""
      },
      //----
      {
        name: "Argon Assault",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ArgonAssault%2FArgonAssault.png?alt=media&token=36c86ccf-64be-4c8f-a14a-1e17af748778",
        order: 70,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ArgonAssault%2FArgonAssault.png?alt=media&token=36c86ccf-64be-4c8f-a14a-1e17af748778",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ArgonAssault%2FArgonAssaultVideo.mp4?alt=media&token=652dfe78-0476-4985-998d-d4315dc79158",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ArgonAssault%2FArgonAssaultPreview1.png?alt=media&token=d4647f6e-d6f4-4579-9d92-935a89ec844d",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ArgonAssault%2FArgonAssaultPreview2.png?alt=media&token=13b144d0-b5e9-4f0b-9b31-830d4e6b08d0",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ArgonAssault%2FArgonAssaultPreview3.png?alt=media&token=6f3e60f6-37c9-4d8c-b117-1ec7a9d97815",
          ],
        description: "You are on a strange planet and enemy ships are after you to capture you. Escape and destroy while piloting Captain Slackbar's ship!",
        finishDate: new Date(1619827200000),
        engine: "Unity",
        downloadUrl: ""
      },
      //----
      {
        name: "Magic Spear",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/MagicSpear%2FMagicSpear.png?alt=media&token=f735cb5e-b62f-4f2d-86a9-a79a2be746d6",
        order: 80,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/MagicSpear%2FMagicSpear.png?alt=media&token=f735cb5e-b62f-4f2d-86a9-a79a2be746d6",
          ],
        description: "You fell into a magical cave and now you're trying to escape. A fairy with the power to become a spear will help you get home!",
        finishDate: undefined,
        engine: "Unity",
        downloadUrl: ""
      },
      //----
      {
        name: "Deer Simulator",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/DeerSimulator%2FDeerSimulator.png?alt=media&token=28146295-59c3-4ff5-96f5-7792eb86def1",
        order: 90,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/DeerSimulator%2FDeerSimulator.png?alt=media&token=28146295-59c3-4ff5-96f5-7792eb86def1",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/DeerSimulator%2FDeerSimulatorVideo.mp4?alt=media&token=b83e7897-c712-41fc-b95f-1b891f797dfc",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/DeerSimulator%2FDeerSimulatorPreview1.png?alt=media&token=2a5e42c6-098d-40f6-9806-1968dbdf9e9c",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/DeerSimulator%2FDeerSimulatorPreview2.png?alt=media&token=5e5a67cc-3436-4cea-b867-2e6f380717d8",
          ],
        description: "A mobile game where you play as a deer and hold on for as long as possible and defeat as many enemies as possible!",
        finishDate: new Date(1601510400000),
        engine: "Unity",
        downloadUrl: "https://play.google.com/store/apps/details?id=com.SousaGames.DeerUltimateSimulatior"
      },
      //----
      {
        name: "Project Boost",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ProjectBoost%2FProjectBoost.png?alt=media&token=c2b5d06b-ef1f-473b-af27-ad60a319079f",
        order: 100,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ProjectBoost%2FProjectBoost.png?alt=media&token=c2b5d06b-ef1f-473b-af27-ad60a319079f",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ProjectBoost%2FProjectBoostVideo.webm?alt=media&token=edd93c02-8cf9-43e2-b049-a303e9aeab59",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ProjectBoost%2FProjectBoostPreview1.png?alt=media&token=bf2033da-5744-48f7-905f-3c554f2d1c7e",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/ProjectBoost%2FProjectBoostPreview2.png?alt=media&token=5d3f4ba4-4da7-4a65-b7ac-5702b63ee2ba",
          ],
        description: "The ship has to land safely on the green spot avoiding obstacles and crashing. This game is a simple air control prototype.",
        finishDate: new Date(1619654400000),
        engine: "Unity",
        downloadUrl: ""
      },
      //----
      {
        name: "Blender Models",
        featurePhotoURL: "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/BlenderModels%2FBlenderModels.png?alt=media&token=8e268fa0-f5c0-4aff-9cfa-0a554f94e0aa",
        order: 110,
        media:
          [
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/BlenderModels%2FBlenderModels.png?alt=media&token=8e268fa0-f5c0-4aff-9cfa-0a554f94e0aa",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/BlenderModels%2FBlenderModelsVideo.mp4?alt=media&token=480c5093-d406-4abc-9c79-9e8a6fa6358b",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/BlenderModels%2FBlenderModelsPreview1.png?alt=media&token=3ef33ddd-82c0-4d32-8790-54b05900bf52",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/BlenderModels%2FBlenderModelsPreview2.png?alt=media&token=fa24374c-ec6f-4dc1-a6c3-24e4d7c2546e",
            "https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/BlenderModels%2FBlenderModelsPreview3.png?alt=media&token=ab851717-1368-4e18-a6ba-06cd2092fb3b",
          ],
        description: "3D models I made to learn how to use the blender software.",
        finishDate: undefined,
        engine: "Blender",
        downloadUrl: "https://sketchfab.com/davimfs7"
      },

    );

    this.languages.push
    (
      //----
      {
        name: "C",
        iconURL: "./../../../assets/images/icons/languages/c-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Arduino Projects",
            "Unreal Engine C++ Game Developer",
            "Unreal Games",
            "OpenGL tutorial",
            "Driver development",
          ],
        proficiency: 65
      },
      //----
      {
        name: "C++",
        iconURL: "./../../../assets/images/icons/languages/cplusplus-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Unreal Engine C++ Game Developer",
            "Unreal Games",
            "Arduino Projects",
            "OpenGL tutorial",
            "Driver development",
          ],
        proficiency: 68
      },
      //----
      {
        name: "C#",
        iconURL: "./../../../assets/images/icons/languages/csharp-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "VideoGame Programming with Unity 3D Course",
            "Programming Instructor",
            "Girosoft Developer",
            "Complete C# Unity Game Developer Course",
            "Unity Games",
          ],
        proficiency: 90
      },
      //----
      {
        name: "css",
        iconURL: "./../../../assets/images/icons/languages/css3-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Technical Course Information Systems Programming",
            "Everis Centers Developer",
            "Programming Instructor",
            "Girosoft Developer",
            "Websites Development",
            "Complete Angular Course",
          ],
        proficiency: 80
      },
      //----
      {
        name: "HTML 5",
        iconURL: "./../../../assets/images/icons/languages/html5-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Technical Course Information Systems Programming",
            "Everis Centers Developer",
            "Programming Instructor",
            "Girosoft Developer",
            "Websites Development",
            "Complete Angular Course",
          ],
        proficiency: 100
      },
      //----
      {
        name: "Java",
        iconURL: "./../../../assets/images/icons/languages/java-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Technical Course Information Systems Programming",
            "Everis Centers Developer",
            "Programming Instructor",
            "Personal Android App Development",
          ],
        proficiency: 80
      },
      //----
      {
        name: "JavaScript",
        iconURL: "./../../../assets/images/icons/languages/javascript-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Technical Course Information Systems Programming",
            "Everis Centers Developer",
            "Programming Instructor",
            "Girosoft Developer",
            "Websites Development",
            "Complete Angular Course",
          ],
        proficiency: 85
      },
      //----
      {
        name: "Kotlin",
        iconURL: "./../../../assets/images/icons/languages/kotlin-original.svg",
        info:
          [
            "Technical Course Information Systems Programming",
            "Personal Android App Development",
          ],
        proficiency: 40
      },
      //----
      {
        name: "OpenGL",
        iconURL: "./../../../assets/images/icons/languages/opengl-original.svg",
        info:
          [
            "VideoGame Programming with Unity 3D",
            "Personal Reading and Investigation",
          ],
        proficiency: 30
      },
      //----
      {
        name: "PHP",
        iconURL: "./../../../assets/images/icons/languages/php-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Technical Course Information Systems Programming",
            "Programming Instructor",
            "Websites Development",
          ],
        proficiency: 65
      },
      //----
      {
        name: "PL/SQL",
        iconURL: "./../../../assets/images/icons/languages/pl-sql.png",
        info:
          [
            "Everis Centers Developer",
          ],
        proficiency: 45
      },
      //----
      {
        name: "Python",
        iconURL: "./../../../assets/images/icons/languages/python-original.svg",
        info:
          [
            "Technical Course Information Systems Programming",
            "Girosoft Developer",
            "Machine Learning A-Z Python Course",
          ],
        proficiency: 75
      },
      //----
      {
        name: "TypeScript",
        iconURL: "./../../../assets/images/icons/languages/typescript-original.svg",
        info:
          [
            "Complete Angular Course",
            "JavaScript Experience",
            "Websites Development",
          ],
        proficiency: 70
      },
      //----
      {
        name: "Visual Basic",
        iconURL: "./../../../assets/images/icons/languages/visual-basic.png",
        info:
          [
            "Girosoft Developer",
          ],
        proficiency: 65
      },
    );

    this.tools.push
    (
      //----
      {
        name: "Android Studio",
        iconURL: "./../../../assets/images/icons/tools/AndroidStudio.png",
        info:
          [
            "12th grade - Professional Programming Course",
            "Technical Course Information Systems Programming",
            "Personal Android App Development",
          ],
        proficiency: 75
      },
      //----
      {
        name: "Arduino",
        iconURL: "./../../../assets/images/icons/tools/arduino-original.svg",
        info:
          [
            "Arduino Projects",
          ],
        proficiency: 65
      },
      //----
      {
        name: "Blender",
        iconURL: "./../../../assets/images/icons/tools/blender.png",
        info:
          [
            "Blender Models",
          ],
        proficiency: 70
      },
      //----
      {
        name: "Gimp",
        iconURL: "./../../../assets/images/icons/tools/gimp-original.svg",
        info:
          [
            "VideoGame Programming with Unity 3D",
          ],
        proficiency: 65
      },
      //----
      {
        name: "IntelliJ",
        iconURL: "./../../../assets/images/icons/tools/intellij-original.svg",
        info:
          [
            "Technical Course Information Systems Programming",
            "Everis Centers Developer",
          ],
        proficiency: 78
      },
      //----
      {
        name: "Source Tree",
        iconURL: "./../../../assets/images/icons/tools/sourcetree-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Girosoft Developer",
          ],
        proficiency: 70
      },
      //----
      {
        name: "Visual Studio",
        iconURL: "./../../../assets/images/icons/tools/visualstudio-plain.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "VideoGame Programming with Unity 3D",
            "Programming Instructor",
            "Girosoft Developer",
          ],
        proficiency: 70
      },
      //----
      {
        name: "Visual Studio Code",
        iconURL: "./../../../assets/images/icons/tools/vscode-original.svg",
        info:
          [
            "Technical Course Information Systems Programming",
            "VideoGame Programming with Unity 3D",
            "Everis Centers Developer",
            "Programming Instructor",
            "Girosoft Developer",
            "Complete C# Unity Game Developer",
          ],
        proficiency: 97
      },
      //----
      {
        name: "Unity 3D",
        iconURL: "./../../../assets/images/icons/engines/UnityLogo.png",
        info:
          [
            "VideoGame Programming with Unity 3D",
            "Complete C# Unity Game Developer",
            "Unity Games",
          ],
        proficiency: 90
      },
      //----
      {
        name: "Unreal",
        iconURL: "./../../../assets/images/icons/engines/UnrealLogo.png",
        info:
          [
            "Unreal Games",
          ],
        proficiency: 50
      },
      //----
      {
        name: "Godot",
        iconURL: "./../../../assets/images/icons/engines/godot-original.svg",
        info:
          [
            "Godot Games",
          ],
        proficiency: 70
      },
    );

    this.technologies.push
    (
      //----
      {
        name: "Apache",
        iconURL: "./../../../assets/images/icons/technologies/apache-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Technical Course Information Systems Programming",
            "Websites Development",
          ],
        proficiency: 60
      },
      //----
      {
        name: "Firebase",
        iconURL: "./../../../assets/images/icons/technologies/firebase-plain.svg",
        info:
          [
            "Technical Course Information Systems Programming",
            "Programming Instructor",
            "Websites Development",
          ],
        proficiency: 75
      },
      //----
      {
        name: "Git",
        iconURL: "./../../../assets/images/icons/technologies/git-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Technical Course Information Systems Programming",
            "Everis Centers Developer",
            "Programming Instructor",
            "Girosoft Developer",
            "Personal Projects",
          ],
        proficiency: 95
      },
      //----
      {
        name: "Gradle",
        iconURL: "./../../../assets/images/icons/technologies/gradle-plain.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Technical Course Information Systems Programming",
            "Personal Android App Development",
          ],
        proficiency: 25
      },
      //----
      {
        name: "NodeJS",
        iconURL: "./../../../assets/images/icons/technologies/nodejs-original.svg",
        info:
          [
            "Personal Reading and Investigation",
          ],
        proficiency: 40
      },
      //----
      {
        name: "Tensorflow",
        iconURL: "./../../../assets/images/icons/technologies/tensorflow-original.svg",
        info:
          [
            "Machine Learning A-Z Python",
          ],
        proficiency: 30
      },
      //----
      {
        name: "Angular",
        iconURL: "./../../../assets/images/icons/frameworks/angularjs-original.svg",
        info:
          [
            "Everis Centers Developer",
            "Complete Angular Course",
            "Websites Development",
          ],
        proficiency: 75
      },
      //----
      {
        name: "Bootstrap",
        iconURL: "./../../../assets/images/icons/frameworks/bootstrap-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Technical Course Information Systems Programming",
            "Everis Centers Developer",
            "Girosoft Developer",
            "Complete Angular",
            "Websites Development",
          ],
        proficiency: 85
      },
      //----
      {
        name: "NumPy",
        iconURL: "./../../../assets/images/icons/frameworks/numpy-original.svg",
        info:
          [
            "Machine Learning A-Z Python",
            "Mathematical Foundations of Machine Learning",
          ],
        proficiency: 65
      },
      //----
      {
        name: "Pandas",
        iconURL: "./../../../assets/images/icons/frameworks/pandas-original.svg",
        info:
          [
            "Machine Learning A-Z Python",
            "Mathematical Foundations of Machine Learning",
          ],
        proficiency: 50
      },
    );

    this.databases.push
    (
      //----
      {
        name: "SQLserver",
        iconURL: "./../../../assets/images/icons/databases/microsoft-sql-server.png",
        info:
          [
            "12th grade - Professional Programming Course",
            "Everis Centers Developer",
            "Programming Instructor",
            "Girosoft Developer",
          ],
        proficiency: 90
      },
      //----
      {
        name: "MySQL",
        iconURL: "./../../../assets/images/icons/databases/mysql-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Technical Course Information Systems Programming",
            "Programming Instructor",
          ],
        proficiency: 85
      },
      //----
      {
        name: "Oracle",
        iconURL: "./../../../assets/images/icons/databases/oracle-original.svg",
        info:
          [
            "12th grade - Professional Programming Course",
            "Technical Course Information Systems Programming",
            "Programming Instructor",
            "Everis Centers Developer",
          ],
        proficiency: 85
      },
      //----
      {
        name: "MongoDB",
        iconURL: "./../../../assets/images/icons/databases/mongodb-original.svg",
        info:
          [
            "MongoBD M001 Course",
          ],
        proficiency: 50
      },
    );

    this.projects.sort((n1,n2) => {
      if(n1.order > n2.order)
        return 1;
      if(n1.order < n2.order)
        return -1;
      return 0;
    });
    this.languages.sort((n1,n2) => {
      if(n1.proficiency < n2.proficiency)
        return 1;
      if(n1.proficiency > n2.proficiency)
        return -1;
      return 0;
    });
    this.tools.sort((n1,n2) => {
      if(n1.proficiency < n2.proficiency)
        return 1;
      if(n1.proficiency > n2.proficiency)
        return -1;
      return 0;
    });
    this.technologies.sort((n1,n2) => {
      if(n1.proficiency < n2.proficiency)
        return 1;
      if(n1.proficiency > n2.proficiency)
        return -1;
      return 0;
    });
    this.databases.sort((n1,n2) => {
      if(n1.proficiency < n2.proficiency)
        return 1;
      if(n1.proficiency > n2.proficiency)
        return -1;
      return 0;
    });
  }

  getProjects()
  {
      return this.projects;
  }

  getLanguages()
  {
      return this.languages;
  }

  getTools()
  {
      return this.tools;
  }

  getTechnologies()
  {
      return this.technologies;
  }

  getDatabases()
  {
      return this.databases;
  }

}
