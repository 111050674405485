<div matRipple class="language-box">

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <div class="language-box-header">
          <img class="language-icon" [src]="language?.iconURL">
          <span class="language-label">{{ language?.name }}</span>
          <mat-progress-bar class="language-proficiency" mode="determinate" [value]="language?.proficiency" color="primary"></mat-progress-bar>
        </div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="language-content">
          <h3>Used in:</h3>
          <p *ngFor="let info of this.language?.info;">{{ info }}</p>
        </div>
      </ng-template>
    </mat-expansion-panel>

</div>
