<div class="container-fluid">
  <div class="row">
    <div class="col-12 col-md-6 col-xl-4 no-padding" *ngFor="let project of this.getProjects();">
      <app-project-card [project]="project"></app-project-card>
    </div>
    <div class="col-12 col-md-6 col-xl-4 no-padding" *ngIf="preview">
      <app-project-card [preview]="true"></app-project-card>
    </div>
  </div>
</div>
