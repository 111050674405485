export class Project {

  public order = 10;
  public media: string[] = [];
  public description = "";
  public finishDate: Date | undefined;
  public engine: String = "";
  public downloadUrl: string = "";

  constructor(public name: string, public featurePhotoURL: string) {

  }

}
