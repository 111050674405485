<div class="image-card" [style.background-image]="'url(' + project!.featurePhotoURL + ')'" [style.height]="height + 'px'" *ngIf="!preview">
  <div class="mask-overlay" (click)="openDialog()">
    <div class="text-overlay" >{{ project!.name }}</div>
  </div>
</div>
<div class="image-card" [style.background-image]="'url(https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/BlenderModels%2FBlenderModels.png?alt=media&token=8e268fa0-f5c0-4aff-9cfa-0a554f94e0aa)'" [style.height]="height + 'px'" *ngIf="preview">
  <div routerLink="/projects" routerLinkActive="active" class="mask-overlay">
    <div class="text-overlay" >See more project...</div>
  </div>
</div>



