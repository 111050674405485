<div class="container">
  <div class="row">
    <!--General Info-->
    <div class="col-12" >
      <div class="text-center mt-4">
        <img src="./../../../assets/images/David_Sousa.jpg" class="img-thumbnail self-picture" alt="David Sousa">
      </div>
      <div class="text-center about-me-accordion">
        <mat-accordion>
          <mat-expansion-panel (opened)="panelOpenState = true"
                               (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="about-me-details-title">David Sousa</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="text-left">
              <mat-divider></mat-divider>
              <p class="mt-2"><span class="text-keyword text-title">Developer</span> &#123;</p>
              <p class="text-comment tab">/* Organized and dedicated professional who enjoys working in a group. Constantly seeks to learn new technologies and tools. */</p>
              <p class="tab">Mobile_Phone: <span class="text-number">(+351) 937 104 110</span></p>
              <p class="tab">Address: <span class="text-string">"2735-570, Cacém, Portugal"</span></p>
              <p class="tab">Email: <span class="text-string">"davimfs7@gmail.com"</span></p>
              <p class="">&#125;</p>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <!--CV-->
    <div class="col-12 col-md-8">
      <!--Experience-->
      <div class="text-left mt-4">
        <p class="text-keyword text-header">Working Experience:</p>
        <p>[&#123;</p>
        <p class="text-title tab">Developer</p>
        <p class="text-comment tab">// Girosoft, October 2020 to December 2021</p>
        <p class="text-string tab">CRM website maintenance and development</p>
        <p class="text-string tab">C#, Python, VB, HTML, CSS, JavaScript, SQLserver, Git</p>
        <p class="">&#125;,</p>
        <p>&#123;</p>
        <p class="text-title tab">Programming Instructor</p>
        <p class="text-comment tab">// Ferreira Dias, September 2019 to September 2020</p>
        <p class="text-string tab">Teaching programming subjects</p>
        <p class="text-string tab">C++, C#, Java, HTML, CSS, PHP, MySQL, Agile, Git</p>
        <p class="">&#125;,</p>
        <p>&#123;</p>
        <p class="text-title tab">Developer</p>
        <p class="text-comment tab">// Everis Centers, January 2018 to September 2019</p>
        <p class="text-string tab">Maintenance of property insurance management website</p>
        <p class="text-string tab">Java, JSP, Servlets, Python, Angular, PL/SQL, SQLServer, Agile, Git</p>
        <p class="">&#125;]</p>
      </div>
      <!--Experience-->
      <div class="text-left mt-4">
        <p class="text-keyword text-header">Education:</p>
        <p>[&#123;</p>
        <p class="text-title tab">VideoGame Programming with Unity 3D</p>
        <p class="text-comment tab">// Master.D, October 2020 to December 2021</p>
        <p class="text-string tab">C#, Blender, Gimp, Photoshop, Unity, Game Design</p>
        <p class="text-string tab">Video game project planning and consulting</p>
        <p class="text-string tab">Asset treatment and management (art, audio, lighting, etc.) </p>
        <p class="text-string tab">Mobile Optimizations</p>
        <p class="">&#125;,</p>
        <p>&#123;</p>
        <p class="text-title tab">Technical Course Information Systems Programming </p>
        <p class="text-comment tab">// Instituto Politécnico de Santarém, 2016 to 2018</p>
        <p class="text-string tab">Java, Python, HTML, CSS, PHP, JavaScript, Android</p>
        <p class="text-string tab">Oracle, Git e Agile</p>
        <p class="text-string tab">Everis Centers Internship</p>
        <p class="">&#125;,</p>
        <p>&#123;</p>
        <p class="text-title tab">12th grade - Professional Programming Course</p>
        <p class="text-comment tab">// Ferreira Dias, 2013 to 2016</p>
        <p class="text-string tab">C++, C#, Java, HTML, CSS, PHP, JavaScript, Android</p>
        <p class="text-string tab">SQLserver, MySQL, Git e Agile</p>
        <p class="text-string tab">Innovagency Internship</p>
        <p class="">&#125;]</p>
      </div>
      <!--Certificates-->
      <div class="text-left mt-4">
        <p class="text-keyword text-header">Certificates:</p>
        <p>[</p>
        <p class="tab"><span class="text-string">"<a class="text-link" href="https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/General%2FCertificates%2FUC-f5bfcfc7-a895-4df4-bfd5-e0ae787f8f99.jpg?alt=media&token=2ebdecef-2c7f-4fed-b943-fa4b0a4a4cc5" target="_blank">Machine Learning A-Z Python</a>"</span>,</p>
        <p class="tab"><span class="text-string">"<a class="text-link" href="https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/General%2FCertificates%2FUC-f8e189d5-af8e-4509-a209-2b2928ced746.jpg?alt=media&token=6ebcc90e-5b41-49ac-8825-d8c9a40420a2" target="_blank">Mathematical Foundations of Machine Learning</a>"</span>,</p>
        <p class="tab"><span class="text-string">"<a class="text-link" href="https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/General%2FCertificates%2FUC-92e79d1d-ea0d-422f-bb5d-b718c8883467.jpg?alt=media&token=43471f56-e1b0-446d-b19d-fc5844bd3175" target="_blank">Complete C# Unity Game Developer</a>"</span>,</p>
        <p class="tab"><span class="text-string">"<a class="text-link" href="https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/General%2FCertificates%2FUC-15e91f70-f54c-4900-8bc6-69aabb2e53a4.jpg?alt=media&token=b47b59b4-940a-4a78-900f-1668d35074cd" target="_blank">Unreal Engine C++ Game Developer</a>"</span>,</p>
        <p class="tab"><span class="text-string">"<a class="text-link" href="https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/General%2FCertificates%2FUC-2ea2fb5b-a478-49f4-bc70-ddf0ca81b164.jpg?alt=media&token=7d51a652-5472-4ca2-ad4e-cf5d93429e5b" target="_blank">Complete Angular</a>"</span>,</p>
        <p class="tab"><span class="text-string">"<a class="text-link" href="https://firebasestorage.googleapis.com/v0/b/portfolio-51b93.appspot.com/o/General%2FCertificates%2Fcertificado_mongodb.pdf?alt=media&token=f9c4b541-1207-4e73-a68c-ddd8a6b18e88" target="_blank">MongoBD M001</a>"</span></p>
        <p>]</p>
      </div>
      <!--Languages-->
      <div class="text-left mt-4">
        <p class="text-keyword text-header">Languages:</p>
        <p>&#123;</p>
        <p class="tab">English: <span class="text-string">"Advanced"</span>,</p>
        <p class="tab">Spanish: <span class="text-string">"Intermediate"</span></p>
        <p>&#125;</p>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <div class="text-left mt-4">
        <p class="text-keyword text-header mt-2">Programming Languages:</p>
        <app-languages [language]="language" *ngFor="let language of this.languages;"></app-languages>
        <!-- <p class="text-keyword text-header mt-5">Tools:</p>
        <app-languages [language]="tool" *ngFor="let tool of this.tools;"></app-languages>
        <p class="text-keyword text-header mt-5">Technologies:</p>
        <app-languages [language]="technology" *ngFor="let technology of this.technologies;"></app-languages>
        <p class="text-keyword text-header mt-5">Databases:</p>
        <app-languages [language]="database" *ngFor="let database of this.databases;"></app-languages> -->
      </div>
    </div>
  </div>
</div>
