<div class="container-fluid">
  <div class="row page-feature-image">
    <div class="col-0 col-md-6"></div>
    <div class="col-12 col-md-6 half-opaque">
      <h1 class="text-center page-title">David<br/>Sousa<br/>Games</h1>
      <h2 class="text-center page-subtitle">Because making games is awesome!</h2>
    </div>
  </div>
</div>
<app-projects-grid [preview]="true"></app-projects-grid>
<app-contact-form></app-contact-form>
