<div matRipple class="language-box">

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <div class="language-box-header">
        <img class="language-icon" [src]="tool?.iconURL">
        <span class="language-label">{{ tool?.name }}</span>
        <mat-progress-bar class="language-proficiency" mode="determinate" [value]="tool?.proficiency" color="primary"></mat-progress-bar>
      </div>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="language-content">
        <h3>Used in:</h3>
        <p *ngFor="let info of this.tool?.info;">{{ info }}</p>
      </div>
    </ng-template>
  </mat-expansion-panel>

</div>
