import { Component, Input, OnInit } from '@angular/core';
import { Tool } from 'src/app/shared/tool.model';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})
export class ToolsComponent implements OnInit {
  @Input() tool: Tool | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
