import { Component, Inject, Input, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Project } from 'src/app/shared/project.model';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.css']
})
export class ProjectCardComponent implements OnInit {

  @Input() project: Project | undefined;
  @Input() height: number = 450;
  @Input() preview = false;

  ngOnInit(): void {
  }

  constructor(public dialog: MatDialog) {}

  openDialog(): void {
    if(window.innerWidth > 576)
    {
      const dialogRef = this.dialog.open(DialogProject, {
        height: '800px',
        width: '1000px',
        data: this.project!,
      });
    }
    else
    {
      const dialogRef = this.dialog.open(DialogProject, {
        maxWidth: '100vw',
        maxHeight: '100vh',
        height: '100%',
        width: '100%',
        data: this.project!,
      });
    }
  }

}

@Component({
  selector: 'dialog-project',
  templateUrl: 'dialog-project.html',
  styleUrls: ['./dialog-project.css']
})
export class DialogProject {
  constructor(
    public dialogRef: MatDialogRef<DialogProject>,
    @Inject(MAT_DIALOG_DATA) public data: Project,
    public datepipe: DatePipe
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  isImage(mediaURL: string): boolean
  {
    if(mediaURL.split('.')?.pop()?.includes("png")) return true;
    if(mediaURL.split('.')?.pop()?.includes("jpg")) return true;
    if(mediaURL.split('.')?.pop()?.includes("jpeg")) return true;
    return false;
  }
  isVideo(mediaURL: string): boolean
  {
    if(mediaURL.split('.')?.pop()?.includes("webm")) return true;
    if(mediaURL.split('.')?.pop()?.includes("avi")) return true;
    if(mediaURL.split('.')?.pop()?.includes("mp4")) return true;
    return false;
  }
}
