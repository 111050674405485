<div mat-dialog-content>
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-6">
          <h1>{{ data.name }}</h1>
      </div>
      <div class="col-auto align-self-end">
        <h2>
          <span *ngIf="data.finishDate">{{ this.datepipe.transform(data.finishDate, 'yyyy-MM') }}</span>
          <span *ngIf="!data.finishDate">(In Progress)</span>
          <img class="engine-logo" src="./../../../assets/images/Engines/UnityLogo.png"  *ngIf="data.engine=='Unity'"/>
          <img class="engine-logo" src="./../../../assets/images/Engines/UnrealLogo.png"  *ngIf="data.engine=='Unreal'"/>
          <img class="engine-logo" src="./../../../assets/images/Engines/GodotLogo.png"  *ngIf="data.engine=='Godot'"/>
          <img class="engine-logo" src="./../../../assets/images/Engines/GameMakerLogo.png"  *ngIf="data.engine=='GameMaker'"/>
          <img class="engine-logo" src="./../../../assets/images/Engines/BlenderLogo.png"  *ngIf="data.engine=='Blender'"/>
        </h2>
      </div>
    </div>
  </div>
</div>
<ngb-carousel class="card-carousel" [interval]="-1" [showNavigationIndicators]="false">
  <ng-template ngbSlide *ngFor="let media of data.media;">
    <div class="picsum-img-wrapper" *ngIf="isImage(media)">
      <div class="image-slide" [style.background-image]="'url(' + media + ')'" ></div>
    </div>
    <div *ngIf="isVideo(media)">
      <video class="video-slide" controls>
        <source [src]="media" type="video/webm">
      </video >
    </div>
  </ng-template>
</ngb-carousel>
<div class="container project-description">
  <div class="row">
    <div class="col-9">
      <p class="text-justify">{{ data.description }}</p>
    </div>
    <div class="col-3">
      <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Close</button>
        <a [href]="data.downloadUrl" target="_blank" mat-raised-button color="primary" cdkFocusInitial *ngIf="data.downloadUrl">Play</a>
      </div>
    </div>
  </div>
</div>

