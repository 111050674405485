import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/shared/project.model';
import { ProjectsService } from '../../shared/projects.service';

@Component({
  selector: 'app-projects-grid',
  templateUrl: './projects-grid.component.html',
  styleUrls: ['./projects-grid.component.css']
})
export class ProjectsGridComponent implements OnInit {
  projects: Project[] = [];
  @Input() preview = false;
  maxPreview = 8;

  constructor(private projectsService: ProjectsService) { }

  ngOnInit(): void
  {
    this.projects = this.projectsService.getProjects();

  }

  getProjects(): Project[]
  {
    if(this.preview)
      return this.projects.slice(0, this.maxPreview);
    else
      return this.projects;
  }

}
