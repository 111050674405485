import { Component, OnInit } from '@angular/core';
import { Language } from 'src/app/shared/language.model';
import { Technology } from 'src/app/shared/technology.model';
import { Tool } from 'src/app/shared/tool.model';
import { ProjectsService } from '../../shared/projects.service';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.css']
})
export class AboutPageComponent implements OnInit {
  languages: Language[] = [];
  tools: Language[] = [];
  technologies: Language[] = [];
  databases: Language[] = [];
  panelOpenState = false;

  constructor(private projectsService: ProjectsService) {
  }
  ngOnInit(): void {
    this.languages = this.projectsService.getLanguages();
    this.tools = this.projectsService.getTools();
    this.technologies = this.projectsService.getTechnologies();
    this.databases = this.projectsService.getDatabases();
  }

}
