import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProjectsGridComponent } from './Projects/projects-grid/projects-grid.component';
import { ProjectsPageComponent } from './Projects/projects-page/projects-page.component';
import { ContactFormComponent } from './About/contact-form/contact-form.component';
import { AboutPageComponent } from './About/about-page/about-page.component';
import { ExperienceComponent } from './About/experience/experience.component';
import { LanguagesComponent } from './About/languages/languages.component';
import { ToolsComponent } from './About/tools/tools.component';
import { CertificatesComponent } from './About/certificates/certificates.component';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion'
import {MatSnackBarModule} from '@angular/material/snack-bar'
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTreeModule} from '@angular/material/tree';
import { MatSliderModule } from '@angular/material/slider';
import { MenuComponent } from './menu/menu.component';
import {MatRippleModule} from '@angular/material/core';
import { ProjectCardComponent } from './Projects/project-card/project-card.component';
import { DialogProject } from './Projects/project-card/project-card.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule } from '@angular/forms';
import { ProjectsService } from './shared/projects.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    ProjectsGridComponent,
    ProjectsPageComponent,
    ContactFormComponent,
    AboutPageComponent,
    ExperienceComponent,
    LanguagesComponent,
    ToolsComponent,
    CertificatesComponent,
    MenuComponent,
    ProjectCardComponent,
    HomeComponent,
    FooterComponent,
    DialogProject,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    CommonModule,
    HttpClientModule,
    NgbCarouselModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatExpansionModule,
    MatDividerModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatRippleModule,
    MatTabsModule,
    /*MatMenuModule,
    MatIconModule,
    MatSliderModule,
    MatTableModule,
    MatPaginatorModule,
     MatSortModule */
  ],
  providers: [ProjectsService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
