<div class="container-fluid">
  <div class="row">
    <!--contact-info-->
    <div class="col-12 col-md-6">
      <div class="contact-info">
        <h1 class="text-center">Get in Touch</h1>
        <h3 class="text-center">Interested in learning more about my work or how we can collaborate <br />on an upcoming project? Reach out today.</h3>
        <br />
        <h3 class="text-center">davimfs7@gmail.com</h3>
        <br />
        <h3 class="text-center">(+351) 937 104 110</h3>
      </div>
    </div>
    <!--contact-form-->
    <div class="col-12 col-md-6">
      <div class="contact-form">
        <form class="example-form" (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Ex. John" type="text" name="name" ngModel required #name="ngModel">
          </mat-form-field>
          <br />
          <mat-form-field class="example-full-width" appearance="fill" >
            <mat-label>Email</mat-label>
            <input matInput placeholder="Ex. my.email@mail.com" type="email" name="email" ngModel required #email="ngModel">
          </mat-form-field>
          <br />
          <mat-form-field class="example-full-width" appearance="fill" >
            <mat-label>Message</mat-label>
            <textarea matInput placeholder="Type your message here..." style="height: 60px;" name="messages" ngModel required #messages="ngModel"></textarea>
          </mat-form-field>
          <button mat-raised-button color="primary" style="float: right;" type="submit">Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>
